



















import Wrapper from './wrapper.vue'

data = {
  data: ->
    unreadNotifications: []
    allNotifications: []
    showComponent: false
    loading: false
    unreadNotificationsCount: 0
    tab: 'unread'
    markAllAsReadUrl: null
    notificationSettingsUrl: null
    loadMoreUrl: null
    canLoadMore: false
}

export default {
  name: 'Notifications'

  mixins: [data]

  methods:
    getUserData: ->
      $.ajax({
        url: @currentUserDataUrl
        method: 'GET'
        dataType: 'json'
        success: @onSuccessUserData
      })

    onSuccessUserData: (data) ->
      @unreadNotificationsCount = data.unread_notifications_count
      @allNotifications = data.read_notifications
      @unreadNotifications = data.unread_notifications
      @markAllAsReadUrl= data.mark_all_as_read_url
      @notificationSettingsUrl = data.notification_settings_url
      @loadMoreUrl = data.load_more_url
      @canLoadMore = data.can_load_more
      if @unreadNotificationsCount > 0 then @tab = 'unread' else @tab = 'all'
      @loading = true

    onItemActions: (item) ->
      url = if item.is_read then item.mark_as_unread_url else item.mark_as_read_url
      $.ajax({
        url: url
        method: 'PATCH'
        dataType: 'json'
        success: @onSuccessItemActions
      })

    onSuccessItemActions: ({notification_id, read_status, status}) ->
      if status == 'success'
        @updateItemReadStatus(notification_id, read_status)

    updateItemReadStatus: (notification_id, read_status) ->
      all_notification_index = @allNotifications.findIndex (n) => parseInt(n.notification_id) == parseInt(notification_id)
      unread_notification_index = @unreadNotifications.findIndex (n) => parseInt(n.notification_id) == parseInt(notification_id)

      @allNotifications[all_notification_index].is_read = read_status if @allNotifications[all_notification_index]

      if @unreadNotifications[unread_notification_index]
        if read_status
          @unreadNotifications.splice(unread_notification_index, 1)
        else
          @unreadNotifications[unread_notification_index].is_read = read_status
      else
        @unreadNotifications.push(@allNotifications[all_notification_index])
        # сортировка по notification_id, внимательно
        @unreadNotifications = @unreadNotifications.sort((x, y) -> y.notification_id - x.notification_id)

      if read_status
        @unreadNotificationsCount--
      else
        @unreadNotificationsCount++
      @changeHeaderUnreadNotificationsCounts()

    changeHeaderUnreadNotificationsCounts: ->
      target = $('body').find('.header__notification-counts')

      return if target.length == 0

      target.empty()
      target.text(@unreadNotificationsCount) if @unreadNotificationsCount > 0

    onTabChange: (tab) ->
      @tab = tab

    onMarkAllAsRead: ->
      return unless @markAllAsReadUrl
      $.ajax({
        url: @markAllAsReadUrl
        method: 'PATCH'
        dataType: 'json'
        success: @onSuccessMarkAllAsRead
      })

    onSuccessMarkAllAsRead: ({status}) ->
      if status == 'success'
        @tab = 'all'
        for notification in @allNotifications
          notification.is_read = true
        @unreadNotifications = []
        @unreadNotificationsCount = 0
        @changeHeaderUnreadNotificationsCounts()
        $('#notification-dropdown').foundation('close')

    onLoadMore: ->
      return unless @loadMoreUrl

      notifications = if @tab == 'all' then @allNotifications else @unreadNotifications
      last_receipt_id = null
      if notifications.length > 0
        last_receipt_id = notifications[notifications.length - 1].receipt_id
      data = {}
      data.is_read = false if @tab == 'unread'
      data.last_receipt_id = last_receipt_id if last_receipt_id

      $.ajax({
        url: @loadMoreUrl
        method: 'GET'
        dataType: 'json'
        data: data
        success: @onSuccessLoadMore
      })

    onSuccessLoadMore: ({status, notifications, can_load_more}) ->
      if status == 'success'
        unreadNotificationIds = @unreadNotifications.map((x) -> x.notification_id)
        if @tab == 'all'
          @allNotifications = @allNotifications.concat(notifications)
          for notification in notifications
            if notification.is_read == false && !unreadNotificationIds.includes(notification.notification_id)
              @unreadNotifications.push(notification)
        if @tab == 'unread'
          notifications = notifications.filter((x) -> !unreadNotificationIds.includes(x.notification_id))
          @unreadNotifications = @unreadNotifications.concat(notifications)
        # сортировка по notification_id, внимательно
        @unreadNotifications = @unreadNotifications.sort((x, y) -> y.notification_id - x.notification_id)
        @canLoadMore = can_load_more if @tab == 'all'

  computed:
    renderedNotifications: ->
      if @tab == 'all'
        @allNotifications
      else
        @unreadNotifications

  watch:
    showComponent: (val) ->
      padding_elems = $('body, .header, .project-header')
      if val
        scrollbar_width = document.body.clientWidth
        $('body').css('overflow', 'hidden')
        scrollbar_width -= document.body.clientWidth
        padding_elems.css('padding-right', -1 * scrollbar_width)
      else
        padding_elems.css({'overflow': '', 'padding-right': ''})

  mounted: ->
    $(@$el).foundation()
    @getUserData()

  components: { Wrapper }
}
