































import ContentHeader from './content_header.vue'
import ContentList from './content_list.vue'
import Empty from './empty.vue'

export default {
  name: 'Content'

  methods:
    onItemActions: (item) ->
      @$emit('onItemActions', item)

    onTabChange: (tab) ->
      @$emit('onTabChange', tab)

    onLoadMore: ->
      @$emit('onLoadMore')

  props:
    unreadNotificationsCount: Number
    notifications: Array
    tab: String
    loading: Boolean
    notificationSettingsUrl: String
    canLoadMore: Boolean

  computed:
    showContentList: ->
      @notifications.length > 0 && @loading

    showEmpty: ->
      @notifications.length == 0 && @loading ||
        @tab == 'unread' && @unreadNotificationsCount > 0 && @notifications.length == 0

  components: { ContentHeader, ContentList, Empty }
}
