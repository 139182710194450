




























export default {
  name: 'ContentItem'

  props:
    item:
      type: Object
      default: {}

  methods:
    goUrl: ->
      window.location.href = @item.target_url

    itemActions: ->
      @$emit('onItemActions', @item)
}
