





















export default {
  name: 'ContentHeader'

  methods:
    onTabChange: (tab) ->
      @$emit('onTabChange', tab)

  props:
    unreadNotificationsCount: Number
    tab: String
    notificationSettingsUrl: String
}
