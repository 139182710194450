

















import LoadMore from './load_more.vue'

export default {
  name: 'Empty'

  props:
    tab: String
    canLoadMore: Boolean
    notifications: Array
    unreadNotificationsCount: Number

  methods:
    onLoadMore: ->
      @$emit('onLoadMore')

  computed:
    renderLoadMore: ->
      if @tab == 'all'
        @canLoadMore
      else
        @notifications.length < @unreadNotificationsCount || @notifications.length == 0 && @unreadNotificationsCount > 0

    emptyStatusText: ->
      if @tab == 'all'
        [@$I18n('notifications.empty_status_all_text')]
      else if @tab == 'unread'
        if @renderLoadMore
          [@$I18n('notifications.empty_status_need_load_text'), @$I18n('notifications.empty_status_need_load_text_tip')]
        else
          [@$I18n('notifications.empty_status_unread_text')]


  components: { LoadMore }
}
