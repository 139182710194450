import Notifications from "../components/notifications/notifications.vue"

notifications = [
  {selector: 'notifications-app', component: Notifications}
]

window.Agiki ||= {}
window.Agiki.Notifications ||= {}
window.Agiki.Notifications.RegisterComponent = (scope = document) =>
  window.Agiki.Common.VueComponentInitializer.initComponents(components: notifications, scope: scope)

document.addEventListener 'turbolinks:load', () ->
  $(document).on 'click', '.js-notification-icon', (event) ->
    event.preventDefault()
    unless window.Agiki.Notifications.instance
      window.Agiki.Notifications.instance = window.Agiki.Notifications.RegisterComponent()
    $component = window.Agiki.Notifications.instance[0] # notifications-app
    $component.showComponent = !$component.showComponent
