



























import Content from './content.vue'

export default {
  name: 'Wrapper'

  props:
    unreadNotificationsCount: Number
    notifications: Array
    tab: String
    loading: Boolean
    notificationSettingsUrl: String
    canLoadMore: Boolean

  methods:
    hideComponent: ->
      @$emit('hideComponent')

    onItemActions: (item) ->
      @$emit('onItemActions', item)

    onTabChange: (tab) ->
      @$emit('onTabChange', tab)

    onMarkAllAsRead: ->
      @$emit('onMarkAllAsRead')

    onLoadMore: ->
      @$emit('onLoadMore')

  components: { Content }

  mounted: ->
    $(@$el).foundation()
}
