






export default {
  name: 'LoadMore'

  methods:
    onLoadMore: ->
      @$emit('onLoadMore')

  computed:
    loadMoreText: ->
      @$I18n('notifications.load_more')
}
