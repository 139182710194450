
















import ContentItem from './content_item.vue'
import LoadMore from './load_more.vue'

export default {
  name: 'ContentList'

  props:
    notifications: Array
    tab: String
    canLoadMore: Boolean
    unreadNotificationsCount: Number

  methods:
    onItemActions: (item) ->
      @$emit('onItemActions', item)

    onLoadMore: ->
      @$emit('onLoadMore')

  computed:
    renderLoadMore: ->
      if @tab == 'all'
        @canLoadMore
      else
        @notifications.length < @unreadNotificationsCount || @notifications.length == 0 && @unreadNotificationsCount > 0

  components: { LoadMore, ContentItem }
}
